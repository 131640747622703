.Image {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  display: block;
  margin: 0 auto; /* center */
  left: 0; /* center */
  right: 0; /* center */

  object-fit: cover;
}

.ModalBody {
  position: relative;
  height: calc(100vh - 225px);
}

.ButtonContainer {
  width: 100%;
  position: absolute;
  bottom: 0;

}
