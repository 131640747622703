.Image {
  position: relative;
  cursor: pointer;
  border: 1px solid var(--mantine-color-gray-2);
  border-radius: var(--mantine-radius-md);
  width: 100%;
  height: auto;
}

.ImageWrapper {
  align-self: center;
  aspect-ratio: 1.3 / 1;
  display: flex;
}

.ImagesContainer {
  justify-content: space-around;
}
