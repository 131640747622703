.Container {
  padding: 1rem 1rem 3rem;
  position: relative;
  min-height: 200px;
}

.header {
  background-color: var(--mantine-color-blue-5);
  font-family: 'Greycliff CF', var(--mantine-font-family);
  color: var(--mantine-color-white);
}
