:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: bg-white;
    --background-end-rgb: bg-orange-600;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 0, 255, 0;
        --background-start-rgb: bg-white;
        --background-end-rgb: bg-orange-600;
    }
}

body {
    background: linear-gradient(
            to bottom,
            rgb(var(--background-start-rgb)),
            rgb(var(--background-end-rgb))
    ) rgb(var(--background-start-rgb));
}
